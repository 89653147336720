@import 'environment';
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

@import '~@angular/material/theming';
$custom-typography: mat-typography-config(
    $font-family: 'Assistant'
);
@include angular-material-typography($custom-typography);

$primary-color: rgba(115, 95, 89, 1);
$background-color: rgba(128, 130, 133, 1);
$primary-color-muted: rgba(115, 95, 89, 0.6);
$man-primary-color: rgba(91, 157, 180, 1);
$man-accent-color: rgba(91, 157, 180, 0.6);
$woman-primary-color: rgba(176, 145, 196, 1);
$woman-accent-color: rgba(176, 145, 196, 0.6);

$desktop-interface: true;
$desktop-min-width: 600px;
$ultra-desktop-min-width: 1920px;
$desktop-resize-width: 410px;

@if $desktop-interface == true {
  @media screen and (min-width: $desktop-min-width) {
    body {
      background-image: url("/assets/svg/birds-bench.svg"), linear-gradient(0deg, white calc(90vh - 64px), rgba(91, 157, 180, 0.3) calc(10vh + 64px));
      background-attachment: fixed;
      background-size: 180px, 100% 100%;
      background-repeat: no-repeat, no-repeat;
      background-position: calc(50% - 330px) 100%, 100% 100%;
    }
    .app-root {
      background-color: white;
      width: $desktop-resize-width;
      margin: auto;
      margin-top: 6.5vh;
      margin-bottom: 5vh;
      box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
      min-height: 85vh;
    }
  }
}

body {
  margin: 0;
}

html, .material, input {
  font-family: 'Assistant', Arial, sans-serif;
  color: $primary-color; // TODO
}

.app-root {
  padding-bottom: 20px; // TODO
}

.text {
  color: $primary-color;
  font-family: 'Assistant', Arial, sans-serif;
  
  &__light {
    font-weight: lighter;
  }
  
  &__bold {
    font-weight: 600;
  }
  
  &__very_bold {
    font-weight: 700;
  }

  &__title {
    font-size: 28px;
    line-height: 32px;
  }
  
  &__large {
    font-size: 24px;
    line-height: 28px;
  }

  &__normal {
    font-size: 20px;
    line-height: 24px;
  }
  
  &__small {
    font-size: 18px;
    line-height: 24px;
  }
  
  &__smaller {
    font-size: 16px;
    line-height: 20px;
  }

  &__blue {
    color: $man-primary-color;
  }
}

.inner-spacing div {
  margin-bottom: 5px;
}

.background {
  background-color: $primary-color !important;
  color: white !important;
}

.foreground {
  color: $primary-color !important;
}

.center {
  text-align: center;
}

a, a div {
  color: inherit;
  text-decoration: none;
}

top-bar mat-toolbar.mat-toolbar {
    margin-bottom: 20px;
}

button {
  width: 100%;
  height: 48px;

  &.button {
    background-color: $primary-color;
    color: white;
  }

  &.button:disabled {
    background-color: $primary-color-muted !important;
    color: white !important;
  }
}

.paragraph {
  margin-bottom: 5px;
}

.back-to-account {
  height: 56px;
  
  & div.text {
    color: white;
  }
}

input.ng-invalid.submitted, .ng-invalid.submitted .mat-button-toggle-appearance-standard {
  border: 1px solid red !important;
  box-shadow: inset 0px 0px 0px 1px red;
}

::ng-deep .select.ng-invalid.submitted.mat-form-field-appearance-outline .mat-form-field-outline {
  color: red;

  & div {
    border-top: 2px solid currentColor;
    border-bottom: 2px solid currentColor;

    &.mat-form-field-outline-start {
      border-right: 2px solid currentColor;
    }
    &.mat-form-field-outline-end {
      border-left: 2px solid currentColor;
    }
  }
}